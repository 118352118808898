
  textarea{
    background-color: rgb(235, 241, 241);
    width: 100%;
    padding: 8px 18px;
    margin: 9px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
}