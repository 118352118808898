.navbar{
    background-color: #76a349;

}

.navbar-img{
    padding: 2px;
    padding-left: 2%;
    border-radius: 90px;
}



