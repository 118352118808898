
.colorletraselect{
    background: #76a349;
    height: 100px;
   
}

/*.modal-dialog{
    width: 100%;
    margin-top: 10%;
  }
  td.a {background-color: #243ff0;}
  .colormodal{
    background: #76a349;
}*/