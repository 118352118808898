
.img-pequena{
    width: 50; height: 50;
    /*width: 100%;*/
    float: central;
  }
 /* img {float: right;
    width:60px;  
    height:60px;
    padding: 2px;}*/

    .modal-background {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: rgba(10, 10, 10, 0.86);
      }

      .mensajealerta {
        color: #80C342;
      }
      .mensajeadvertencia {
        color: #ffae00;
      }
