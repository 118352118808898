.centrar{
   
    margin-left: auto;
    margin-right: auto;
}

.head-form{
    background: #76a349;
    height: 100px;
    /*IMPORTANTE*/
    display: flex;
    justify-content: center;
    align-items: center;
}